<template>
  <div class="CEESAR-ScenarioCard">
    <div class="CEESAR-ScenarioCard-Description">
      {{description}}
    </div>
    <div class="CEESAR-ScenarioCard-Pictogram">
      <img :src="pictogram" />
    </div>

    <CEESAR-ScenarioCardList label="Categorie(s)" :items="categories"
      v-on:click="toggleCategories" :withDetails="detailedCategories"/>
    <CEESAR-ScenarioCardList label="Infrastructure(s)" :items="infrastructures"
      v-on:click="toggleInfrastructures" :withDetails="detailedInfrastructures"/>
    <CEESAR-ScenarioCardList label="AV Trajectory(ies)" :items="trajectories_AV"
      v-on:click="toggleTrajectories_AV" :withDetails="detailedTrajectories_AV"/>
    <CEESAR-ScenarioCardList label="Other User(s)" :items="otherUsers"
      v-on:click="toggleOtherUsers" :withDetails="detailedOtherUsers"/>
    <CEESAR-ScenarioCardList label="Other User(s) Trajectory(ies)" :items="trajectories_otherUsers"
      v-on:click="toggleTrajectories_otherUsers" :withDetails="detailedTrajectories_otherUsers"/>

  </div>
</template>

<script>
export default {
    name: "CEESAR-ScenarioCard",
    props:{
      scenario: {}
    },
    watch:{
        scenario: {
            immediate: true,
            handler(newVal, oldVal){
                this.loadScenarioData(newVal);
            }
        }
    },
    async mounted(){
      await this.loadScenarioData(this.scenario);
    },
    data(){
      return{
        description: "",
        pictogram: "",

        detailsMode: "picto",

        categories: [],
        detailedCategories: false,
        infrastructures: [],
        detailedInfrastructures: false,
        otherUsers: [],
        detailedOtherUsers: false,
        trajectories_otherUsers: [],
        detailedTrajectories_otherUsers: false,
        trajectories_AV: [],
        detailedTrajectories_AV: false
      };
    },
    methods:{
        async loadScenarioData(pScenario)
        {
          this.description = "";
          this.pictogram = "";

          if(pScenario == undefined) return;

          this.description = pScenario.description;

          if(pScenario.asset_id > 0)
          {
            let asset = await this.$ceesar.backend.GetModelByIdAsync("asset", pScenario.asset_id);
            this.pictogram = this.$ceesar.backend.imagesPath + asset.type + "/" + asset.stored_file_name;
          }

          this.categories = await this.loadTable("category", pScenario.category_Ids);
          this.infrastructures = await this.loadTable("infrastructure", pScenario.infrastructure_Ids);
          this.otherUsers = await this.loadTable("road_user", pScenario.road_user_Ids);
          this.trajectories_otherUsers = await this.loadTable("trajectory", pScenario.road_user_trajectory_Ids);
          this.trajectories_AV = await this.loadTable("trajectory", pScenario.av_trajectory_Ids);
        },
        async loadTable(pModel, pLinkIds)
        {
          let linkIds = pLinkIds.split(",");

          let table = [];
          let values = await this.$ceesar.backend.GetModelAsync(pModel, "get_all");
          values.sort((a, b) => a.label.localeCompare(b.label));
          values.forEach(entry =>{
              let res = linkIds.find(id => id == entry.id);
              if(res != undefined)
              {
                table.push(entry);
              }
          });
          return table;
        },
        async hideAll(pId){
          if(pId != 1) this.detailedCategories = false;
          if(pId != 2) this.detailedInfrastructures = false;
          if(pId != 3) this.detailedOtherUsers = false;
          if(pId != 4) this.detailedTrajectories_otherUsers = false;
          if(pId != 5) this.detailedTrajectories_AV = false;
        },
        async toggleCategories()
        {
          this.hideAll(1);
          this.detailedCategories = !this.detailedCategories;
        },
        async toggleInfrastructures()
        {
          this.hideAll(2);
          this.detailedInfrastructures = !this.detailedInfrastructures;
        },
        async toggleOtherUsers()
        {
          this.hideAll(3);
          this.detailedOtherUsers = !this.detailedOtherUsers;
        },
        async toggleTrajectories_otherUsers()
        {
          this.hideAll(4);
          this.detailedTrajectories_otherUsers = !this.detailedTrajectories_otherUsers;
        },
        async toggleTrajectories_AV()
        {
          this.hideAll(5);
          this.detailedTrajectories_AV = !this.detailedTrajectories_AV;
        }
    }
}
</script>

<style lang="less">
  .CEESAR-ScenarioCard{
    background-color: white;
    color:black;
    border-radius: 10px;
    width: 300px;
    
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;

    padding : 5px 5px;

    .CEESAR-ScenarioCard-Description{
      font-size: 14px;
      font-weight: bold;
      border-bottom: 2px solid darkorange;
    }

    .CEESAR-ScenarioCard-Pictogram{
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid darkorange;
    }
  }
</style>